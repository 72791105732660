import React from "react";
import PropTypes from "prop-types";

import { trackCustomEvent } from 'gatsby-plugin-google-analytics'
import { getUser } from "../../services/auth";
import { loadScriptPromise } from "../../services/utility";

export default class BoxcastEmbed extends React.Component {
  constructor(props) {
    super(props);
    this.onPlayerLoaded = this.onPlayerLoaded.bind(this);
    this.onPlayerStateChanged = this.onPlayerStateChanged.bind(this);
  }

  onPlayerLoaded = player => {
    try {
      player.play();
    }
    catch(err) {
      // Ignore autoplay error
    }
  };

  onPlayerStateChanged = newState => {
    let user = getUser();
    if(user && newState === 'play') {
      trackCustomEvent({
        category: "PR2020",
        action: "Boxcast Play",
        label: user.names
      });
    }
  };

  componentDidMount() {
    loadScriptPromise("https://js.boxcast.com/v3.min.js", "BoxcastEmbedScript").then(() => {
      var options = {
        "showTitle": 0,
        "showDescription": 0,
        "showHighlights": 0,
        "showRelated": false,
        "showCountdown": true,
        "market": "smb",
        "showDocuments": false,
        "showIndex": false,
        "showDonations": false,
        "autoplay": false,
        "onLoadPlayer": this.onPlayerLoaded,
        "onPlayerStateChanged": this.onPlayerStateChanged
      };
      var context = window.boxcast('#boxcast-video');
      context.loadChannel(this.props.channelId, options);
    });
  }

  componentWillUnmount() {
    loadScriptPromise("https://js.boxcast.com/v3.min.js", "BoxcastEmbedScript").then(() => {
      var selector = '#boxcast-video';
      var context = window.boxcast(selector);
      if(context) {
        context.unload();
      }
    });
  }

  render() {
    return (
      <div id="boxcast-video"></div>
    );
  }
}

BoxcastEmbed.propTypes = {
  channelId: PropTypes.string.isRequired
};