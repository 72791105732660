export const loadScriptPromise = (scriptPath, uniqueId) => {
  if(typeof window[uniqueId] === 'undefined') {
    window[uniqueId] = new Promise((resolve, reject) => {
      const script = document.createElement('script');
      document.body.appendChild(script);
      script.onload = resolve;
      script.onerror = reject;
      script.async = true;
      script.crossOrigin = undefined;
      script.src = scriptPath;
    });
  }
  return window[uniqueId];
}
