import React from "react";
import { withStyles } from "@material-ui/core";

import Header from "components/Header/Header";
import HeaderLinks from "components/Header/HeaderLinks.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import BoxcastEmbed from "components/BoxcastEmbed/BoxcastEmbed";

import SEO from "../components/seo"

import { container } from "assets/jss/material-kit-pro-react.js";

import "assets/scss/material-kit-pro-react.scss?v=1.9.0";

const pageStyle = theme => ({
  background: {
    position: "fixed",
    left: "0",
    right: "0",
    top: "0",
    bottom: "0",
    zIndex: "1",
    overflow: "visible",
    backgroundImage: "url(" + require("../assets/img/background.gif") + ")",
    backgroundPosition: "center", //"0% 50%"
    backgroundRepeat: "no-repeat",
    backgroundAttachment: "scroll",
    backgroundSize: "cover",    
  },
  main: {
    zIndex: "2",
//    height: "100vh",
    paddingTop: "5vh",
//    minHeight: "750px",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "140px",
    }
  },
  sections: {
    zIndex: "20",
    display: "flex",
    justifyContent: "center",
    paddingBottom: "40px",
    color: "#ffffff"
  },
  container: {
    zIndex: "12",
    color: "#FFFFFF",
    textAlign: "center",
    lineHeight: "1.9rem",
    fontWeight: 200,
    ...container
  },
});

class TempPage extends React.Component {
  render() {
    const { classes, ...rest } = this.props;
    return (
      <div>
        <SEO title="President's Retreat 2020" />
        <Header
          links={<HeaderLinks />}
//          fixed
          color="transparent"
//          changeColorOnScroll={{ height: 0, color: "white" }}
          {...rest}
        />
        <div className={classes.background}></div>
        <div className={classes.main}>
          <div className={classes.sections}>
            <div className={classes.container}>
              <GridContainer justify="center">
                <GridItem xs={12} sm={10} md={8} lg={8}>
                  <BoxcastEmbed channelId="rhm-pr-788024" />
                </GridItem>
              </GridContainer>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(pageStyle)(TempPage);
